export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: 'OverRainbow',

  author: 'mzvast',
  description:
    "If Nothing Changes, Nothing Is Going to Change",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
